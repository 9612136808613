import { FC } from 'react';

import Clickable, { ClickableProps } from '../Clickable';
import HoverBackground from '../HoverBackground';
import ChevronIcon from '../Icon/ChevronIcon';
import darkTheme from '~/app/lib/theme/dark';
import Text, { TextProps } from '../Text';
import Box from '../Box';

import { CARD_STYLE } from '.';
import Loading from '../Loading';

interface CardActionProps
  extends Omit<ClickableProps, 'children' | 'withFocusStyle'> {
  text: string | JSX.Element;
  withChevron?: boolean;
  textAlign?: TextProps['align'];
  isBold?: boolean;
  background?: string;
  textColor?: string;
  borderColor?: string;
  isSecondary?: boolean;
  isDanger?: boolean;
  withRightBorder?: boolean;
  isLoading?: boolean;
  isDisabled?: boolean;
}

const CardAction: FC<CardActionProps> = ({
  height = '5rem',
  text,
  textAlign = 'center',
  withChevron,
  style,
  background = '#1a1a1a',
  textColor,
  borderColor,
  isDisabled,
  isDanger,
  isLoading,
  isSecondary,
  testId,
  onClick,
  href,
  withRightBorder,
  withActiveStyle,
  tag,
  ...boxProps
}) => (
  <Box
    {...boxProps}
    style={{
      ...style,
      borderTop: CARD_STYLE.border,
      borderRight: withRightBorder ? CARD_STYLE.border : undefined,
      fontSize: height,
    }}
  >
    <HoverBackground>
      <Clickable
        tag={tag}
        padding="0 0.3em"
        alignCenter
        flexRow
        fullHeight
        height="1em"
        testId={testId}
        onClick={onClick}
        href={href}
        withFocusStyle={false}
        positionRelative
        withDisabledStyle={false}
        withActiveStyle={withActiveStyle}
        isDisabled={isDisabled || isLoading}
        style={{
          background,
        }}
      >
        {(() => {
          if (isLoading) {
            return <Loading size=".4em" coverParent />;
          }

          const secondaryColor = isSecondary ? '#aaa' : '';
          const dangerColor = isDanger ? darkTheme.colorDanger : secondaryColor;
          const finalColor = isDisabled ? '#666' : dangerColor;

          return (
            <>
              <Text
                isBold={!isSecondary}
                fullWidth
                align={textAlign}
                color={finalColor}
                size="0.3em"
                letterSpacing={0.02}
              >
                {text}
              </Text>
              {withChevron && (
                <Box
                  fullHeight
                  centerContent
                  positionAbsolute
                  right={0}
                  top={0}
                  padding="0 0.2em 0 0"
                >
                  <ChevronIcon size="0.3em" direction="right" />
                </Box>
              )}
            </>
          );
        })()}
      </Clickable>
    </HoverBackground>
  </Box>
);

export default CardAction;
