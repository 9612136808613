import { FC } from 'react';
import css from 'styled-jsx/css';

import Clickable, { ClickableProps } from '../Clickable';

const styles = css.resolve`
  :before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 0;
    background: #fff;
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.5s;
  }

  :active:before {
    opacity: 0;
  }

  :hover:before,
  :focus-visible:before {
    opacity: 0.04;
    transition-delay: 0s;
    transition-duration: 0s;
  }

  [disabled] {
    pointer-events: none !important;
  }
`;

const SelectOptionButton: FC<ClickableProps> = ({
  children,
  ...clickableProps
}) => (
  <Clickable
    positionRelative
    className={styles.className}
    withFocusStyle={false}
    {...clickableProps}
  >
    {children}
    {styles.styles}
  </Clickable>
);

export default SelectOptionButton;
