import { forwardRef } from 'react';
import classnames from 'classnames';
import css from 'styled-jsx/css';

import useIsLargeScreen from '~/app/lib/hooks/useIsLargeScreen';
import { useI18nDynamic } from '~/app/lib/i18n';

import ChevronIcon from '../Icon/ChevronIcon';
import SearchIcon from '../Icon/SearchIcon';
import CrossIcon from '../Icon/CrossIcon';
import Clickable from '../Clickable';
import Box from '../Box';

interface SelectSearchInputProps {
  testId?: string;
  value: string;
  placeholder?: string;
  onChange(value: string): void;
  onClear(): void;
  close(): void;
}

const styles = css.resolve`
  .input {
    border: 0;
    box-shadow: none;
    background-color: transparent;
    color: #fff;
  }

  .input::placeholder {
    color: #999;
  }
`;

const SelectSearchInput = forwardRef<HTMLInputElement, SelectSearchInputProps>(
  (
    { testId = 'select', value, placeholder, onChange, onClear, close },
    forwardedRef
  ) => {
    const { t } = useI18nDynamic('select');
    const isLargeScreen = useIsLargeScreen();

    const input = (
      <input
        ref={forwardedRef}
        data-testid={`${testId}-search`}
        className={classnames(styles.className, 'input')}
        value={value}
        placeholder={placeholder ?? t('searchPlaceholder')}
        autoCorrect="off"
        autoCapitalize="off"
        autoFocus
        spellCheck="false"
        onChange={(event) => onChange(event.target.value)}
        style={{
          padding: 0,
          flexGrow: 1,
          minWidth: 0,
          alignSelf: 'stretch',
          fontSize: isLargeScreen ? '1.4rem' : '1.7rem',
        }}
      />
    );

    return isLargeScreen ? (
      <Box
        flexRow
        centerContent
        height="4.8rem"
        padding="0 1rem"
        style={{ borderBottom: '1px solid #3f3f3f' }}
      >
        <SearchIcon noFlexShrink color="#ccc" margin="0 1rem 0 0" />
        {input}
        <Clickable
          noFlexShrink
          isInline
          margin="0 0 0 1rem"
          style={{ visibility: value.length ? 'visible' : 'hidden' }}
          onClick={onClear}
        >
          <CrossIcon size="1.8rem" color="#999" />
        </Clickable>
        {styles.styles}
      </Box>
    ) : (
      <Box
        flexRow
        centerContent
        height="4.8rem"
        padding="0 1.6rem"
        style={{ borderBottom: '1px solid #3f3f3f' }}
      >
        <Clickable onClick={close} isInline margin="0 1rem 0 0">
          <ChevronIcon direction="left" />
        </Clickable>
        {input}
        {value.length ? (
          <Clickable
            noFlexShrink
            isInline
            margin="0 0 0 1rem"
            onClick={onClear}
          >
            <CrossIcon size="2rem" color="#999" />
          </Clickable>
        ) : (
          <SearchIcon noFlexShrink color="#ccc" margin="0 0 0 1rem" />
        )}
        {styles.styles}
      </Box>
    );
  }
);

export default SelectSearchInput;
