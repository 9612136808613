import React, {
  memo,
  useState,
  useRef,
  useCallback,
  useMemo,
  useEffect,
} from 'react';

import useDebouncedValue from '~/app/lib/hooks/useDebouncedValue';
import useIsLargeScreen from '~/app/lib/hooks/useIsLargeScreen';
import withSpacing from '~/app/lib/hocs/withSpacing';

import AnchoredDialog from '../AnchoredDialog';
import ChevronIcon from '../Icon/ChevronIcon';
import Clickable from '../Clickable';
import Modal from '../Modal';
import Text from '../Text';
import Box from '../Box';

import { SelectOption, SelectProps } from './types';
import { DEFAULT_OPTION_HEIGHT } from './constants';
import SelectSearchInput from './SelectSearchInput';
import SelectOptions from './SelectOptions';
import SelectButton from './SelectButton';

const Select = <T extends SelectOption = SelectOption>(
  props: SelectProps<T>
) => {
  const {
    testId,

    name,
    defaultValue,
    value,
    label,
    placeholder = '',
    options,

    onChange,

    optionHeight = DEFAULT_OPTION_HEIGHT,
    visibleOptionsCount = 5,
    searchFeature,

    renderButton,
    renderOption,

    isLoading = false,
    isDisabled = false,
    isRequired = false,
    isSameWidth = true,

    position: [xPosition, yPosition] = [undefined, undefined],

    ...spacingProps
  } = props;

  const targetRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const isLargeScreen = useIsLargeScreen();

  const [internalValue, setInternalValue] = useState(defaultValue ?? value);
  const [isOpened, setIsOpened] = useState(false);
  const [query, setQuery] = useState('');

  // debounced query helps to avoid laggy user input
  // during large options list filtration
  const debouncedQuery = useDebouncedValue(query, {
    force: !query.length,
    wait: 500,
  });

  // options map helps to get the particular option in performant way
  const optionsMap = useMemo(
    () =>
      options.reduce(
        (map, option) => {
          map[option.id] = option;
          return map;
        },
        {} as Record<T['id'], T>
      ),
    [options]
  );

  const optionsToShow = useMemo(() => {
    if (debouncedQuery.length) {
      return options.filter(({ text, caption }) => {
        const queryToMatch = debouncedQuery.trim().toLowerCase();
        const textTarget = text.trim().toLowerCase();
        const captionTarget = caption?.trim().toLowerCase();

        return [textTarget, captionTarget].some(
          (target) => target?.includes(queryToMatch)
        );
      });
    }

    return options;
  }, [options, debouncedQuery]);

  const onChangeInternal = useCallback<NonNullable<SelectProps['onChange']>>(
    (id) => {
      const value = id === '' ? undefined : id;

      setInternalValue(value);
      onChange?.(value);
    },
    []
  );

  // O(1) selected option retrieving
  const selectedOption = internalValue ? optionsMap[internalValue] : undefined;

  const renderContent = useCallback(
    ({ query, close }: { query: string; close: () => void }) => (
      <Box testId={`${testId || 'select'}-content`} flexColumn fullHeight>
        <Box noFlexShrink>
          {searchFeature ? (
            <SelectSearchInput
              ref={inputRef}
              testId={testId}
              value={query}
              placeholder={searchFeature.placeholder}
              close={close}
              onChange={setQuery}
              onClear={() => {
                setQuery('');
                inputRef.current?.focus();
              }}
            />
          ) : isLargeScreen ? null : (
            <Box
              flexRow
              alignCenter
              height="6.5rem"
              padding="0 1.6rem"
              style={{ borderBottom: '1px solid #3f3f3f' }}
            >
              <Clickable onClick={close} isInline noFlexShrink>
                <ChevronIcon size="2.4rem" direction="left" />
              </Clickable>
              <Text
                isBold
                centered
                flexGrow
                withEllipsis
                size="1.7rem"
                margin="0 1rem"
              >
                {label}
              </Text>
              <Box noFlexShrink width="2.4rem" />
            </Box>
          )}
        </Box>
        <SelectOptions
          testId={testId}
          inputRef={inputRef}
          options={optionsToShow}
          close={close}
          query={debouncedQuery}
          onChange={onChangeInternal}
          renderOption={renderOption}
          optionHeight={optionHeight}
          visibleOptionsCount={visibleOptionsCount}
          searchFeature={searchFeature}
          selectedOption={selectedOption}
        />
      </Box>
    ),
    [selectedOption, optionsToShow, debouncedQuery, isLargeScreen, optionHeight]
  );

  useEffect(() => {
    // reset query after select close
    if (!isOpened) {
      setQuery('');
    }
  }, [isOpened]);

  useEffect(() => {
    // NOTE: Update select value only if value explicitly provided
    // Checking for own property allow to pass undefined as a value to erase select
    if (props.hasOwnProperty('value')) {
      setInternalValue(value);
    }
  }, [value]);

  return (
    <Box
      {...spacingProps}
      testId={testId}
      width="fit-content"
      height="fit-content"
    >
      <Box positionRelative zIndex={1} flexColumn fullWidth fullHeight>
        <div ref={targetRef} style={{ width: 'inherit', height: 'inherit' }}>
          {/* hidden input to pass value to native form and native form validation */}
          <input
            name={name}
            value={internalValue ?? ''}
            tabIndex={-1}
            required={isRequired}
            style={{
              position: 'absolute',
              zIndex: -1,
              top: 0,
              left: 0,
              opacity: '0',
              pointerEvents: 'none',
              width: '100%',
              height: '100%',
            }}
          />
          {renderButton?.({
            isOpened,
            isLoading,
            isDisabled,
            isEmpty: !selectedOption,
            option: selectedOption,
            open: () => setIsOpened(true),
          }) ?? (
            <SelectButton<T>
              testId={testId}
              isOpened={isOpened}
              isLoading={isLoading}
              isDisabled={isDisabled}
              isEmpty={!selectedOption}
              text={selectedOption?.text ?? placeholder}
              open={() => setIsOpened(true)}
            />
          )}
        </div>
      </Box>
      {isOpened &&
        (isLargeScreen ? (
          <AnchoredDialog
            color="#1a1a1a"
            targetElRef={targetRef}
            withTriangle={false}
            xPosition={xPosition}
            yPosition={yPosition}
            matchTargetElWidth={isSameWidth}
            style={{
              overflow: 'hidden',
              minWidth: isSameWidth ? '100%' : '20rem',
              maxWidth: isSameWidth ? '100%' : '40rem',
            }}
            onClose={() => setIsOpened(false)}
            renderContent={({ close }) => renderContent({ query, close })}
          />
        ) : (
          <Modal
            onClose={() => setIsOpened(false)}
            renderHeader={() => <></>}
            renderContent={({ close }) => renderContent({ query, close })}
            style={{
              padding: '0',
              width: '100%',
              height: '100%',
              backgroundColor: '#1a1a1a',
            }}
          />
        ))}
    </Box>
  );
};

const SelectWithSpacing = withSpacing(Select);

export default memo(SelectWithSpacing) as typeof SelectWithSpacing;
