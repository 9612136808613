import { useEffect, useState } from 'react';

import useDebounce from '../useDebounce';

const useDebouncedValue = <T>(
  value: T,
  params?: { wait?: number; force?: boolean }
) => {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  const debouncedValueUpdate = useDebounce(
    (updatedValue) => {
      setDebouncedValue(updatedValue);
    },
    params?.wait ?? 300,
    []
  );

  useEffect(() => {
    if (value === debouncedValue) return;

    if (params?.force) {
      setDebouncedValue(value);
    } else {
      debouncedValueUpdate(value);
    }

    return () => {
      debouncedValueUpdate.clear();
    };
  }, [value, debouncedValue]);

  return debouncedValue;
};

export default useDebouncedValue;
