import { FC } from 'react';
import Box, { BoxProps } from '../Box';

export const CARD_STYLE = {
  border: 'solid 1px #333',
  background: '#111',
  borderRadius: '0.6rem',
  // stop content overflowing rounded corners
  overflow: 'hidden',
};

export type CardProps = BoxProps;

const Card: FC<CardProps> = ({ style, ...props }) => (
  <Box
    style={{
      ...CARD_STYLE,
      ...style,
    }}
    {...props}
  />
);

export default Card;
