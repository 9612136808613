import { useEffect } from 'react';

import debounce from '~/app/lib/utils/debounce';

const useOnWindowResize = ({
  onStart,
  onEnd,
}: {
  onStart: () => void;
  onEnd: () => void;
}) => {
  useEffect(() => {
    let inProgress = false;

    const onResizeDebounced = debounce(() => {
      inProgress = false;
      onEnd();
    }, 500);

    const onResize = () => {
      if (!inProgress) {
        inProgress = true;
        onStart();
      }

      onResizeDebounced();
    };

    addEventListener('resize', onResize);

    return () => {
      onResizeDebounced.clear();
      removeEventListener('resize', onResize);
    };
  }, [onStart, onEnd]);
};

export default useOnWindowResize;
